@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900');
@import url('reset.css');

body {
  -webkit-text-size-adjust: 100%;
  font-family: 'Inter', serif;
  color: #111111;
  font-size: 15px;
  background-color: #fafafa !important;
}

.flex {
  display: flex;
}

h2 {
  font-weight: 500;
  font-size: 16px;
}

.wrap {
  max-width: 600px;
  margin: 0 auto;
}

.chart {
  border-radius: 10px;
  background: #fff;
  border: 1px solid #eee;
  margin-top: 16px;
  padding-bottom: 17px;
}

.offline {
  border-radius: 10px;
  background: #fff;
  border: 1px solid #eee;
  margin-top: 16px;
  padding: 20px;
}

.journal {
  border-radius: 10px 10px 0 0;
  background: #fff;
  border: 1px solid #eee;
  padding: 0 34px 50px 34px;
  margin-top: 16px;
}

.chart-date {
  padding: 23px 10px;
  text-align: center;
  font-weight: 500;
  border-bottom: 1px solid #e4e4e4;
}

.chart-filter {
  margin-top: 30px;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
}

.chart-filter > div {
  height: 30px;
  border: 1px solid #eee;
  border-radius: 100px;
  color: #606872;
  font-size: 12px;
  font-weight: 500;
  align-items: center;
  box-sizing: border-box;
  padding: 0 10px 0 8px;
  margin: 0 8px 8px 0;
  cursor: pointer;
}

.filter-item {
  background: #fff;
}

.filter-item-disable {
  background: #fafafa;
}

div.filter-btn {
  background: #efefef;
  border: 1px solid #efefef;
  padding-left: 10px;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: 6px;
}

.filter-item-disable .circle {
  background: url("/cross.svg") -1px center no-repeat #606872;
  opacity: 0.9;
}

.blue {
  background-color: #7ab1ff;
}

.red {
  background-color: #ff7272;
}

.orange {
  background-color: #fe9e76;
}

.green {
  background-color: #45d09e;
}

.journal-top {
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  padding: 17px 0;
}

.write-tech-support {
  font-family: 'Inter', serif;
  cursor: pointer;
  border: none;
  background: #f6f7f8;
  border-radius: 100px;
  font-weight: 500;
  font-size: 14px;
  color: #606872;
  height: 32px;
  padding: 0 12px;
}

.write-tech-support-link:hover {
  text-decoration: none;
}

.journal-time {
  font-size: 12px;
  color: #fff;
  height: 20px;
  line-height: 20px;
  border-radius: 100px;
  margin-right: 16px;
  padding: 0 11px;
}

.journal-text {
  line-height: 120%;
}

.journal-table-string {
  padding: 22px 10px 22px 0;
  border-bottom: 1px solid #eee;
}

.google-play {
  font-weight: 500;
  font-size: 14px;
  position: fixed;
  bottom: 20px;
  left: 20px;
  padding: 18px 20px 3px 20px;
  background: #fff;
  border: 1px solid #e4e4e4;
  border-radius: 10px;
  width: 260px;
  height: 160px;
  line-height: 135%;
  color: #606872;
}

.google-play-image {
  width: 179px;
  height: 70px;
  margin: 4px 0px 0 -11px;
}

.modal-inner {
  max-width: 1040px;
  background: #fff;
  border-radius: 10px;
  width: 100%;
}

.modal-close {
  width: 21px;
  height: 21px;
  position: absolute;
  top: 26px;
  right: 23px;
  cursor: pointer;
  display: block;
}

#overlay {
  z-index: 3;
  position: fixed;
  background-color: #000;
  opacity: 0.8;
  -moz-opacity: 0.8;
  filter: alpha(opacity=80);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  display: none;
}

.modal-top {
  position: relative;
  padding: 30px 0 24px 0;
  text-align: center;
}

.modal-body > div {
  padding: 24px 32px 17px 32px;
}

.modal-item-text {
  line-height: 130%;
  margin-top: 14px;
  color: #444;
}

.modal-item-title {
  font-weight: 500;
}

.modal-body {
  padding-bottom: 20px;
}

.modal-item-title span {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: 8px;
  position: relative;
  top: 1px;
  display: inline-block;
}

.help a {
  text-decoration: underline;
}

.help table {
  margin: 24px;
}

.help td {
  padding-right: 24px ;
}

.help .modal-body {
  border-top: 1px solid #eee;
}

.help .indicator-title {
  display: inline-flex;
  height: 28px;
  border-radius: 14px;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  padding: 6px 16px;
}

.help .help-image {
  width: 250px;
  text-align: center;
  vertical-align: middle;
  padding-top: 24px;
}

.help .help-image img {
  display: block;
  margin: 10px auto;
}

.help .modal-body {
  padding: 0 0 20px;
  font-size: 14px;
}

.help .level-digits {
  float: left;
  font-size: 32px;
  font-weight: 800;
  margin-right: 14px;
}

.google-mobile {
  height: 92px;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px 0 8px;
  display: flex;
  margin: 0 auto 9px auto;
  max-width: 600px;
  box-sizing: border-box;
  background-color: #fff;
  border-bottom: 1px solid #eee;
}

.google-close {
  cursor: pointer;
}

.google-install {
  color: #fff;
  background: #00b181;
  border-radius: 100px;
  padding: 0 23px;
  height: 40px;
  border: none;
  font-family: 'Inter', serif;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
}

.google-img {
  width: 60px;
  height: 60px;
  border-radius: 100px;
  margin-right: 8px;
}

.google-mobile-content {
  align-items: center;
}

.google-img + div {
  line-height: 120%;
  font-weight: 500;
  font-size: 16px;
}

.google-img + div span {
  font-size: 14px;
  color: #606872;
}

@media only screen and (max-width: 600px) {
  .journal, .chart-filter, .modal-body > div {
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .modal-div {
    align-items: inherit;
    height: auto;
    position: absolute;
    top: 10px;
  }
}

@media only screen and (max-width: 480px) {
  .journal-table-string {
    flex-direction: column;
    align-items: flex-start;
  }

  .journal-time {
    margin-bottom: 14px;
  }
}

@media only screen and (max-width: 375px) {
  .google-install {
    color: #00b181;
    background: none;
    padding: 0;
  }
}

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

#root {
  height: 100vh;
}

.preloader {
  display: flex;
  background-color: #f7fafd;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100vh;
}